import { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import logo from "../../assets/images/logo-drsaude.png";
import mobileFirstStep from "../../assets/images/first-step-mobile.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HttpsIcon from "@mui/icons-material/Https";
import { useStyles } from "./styles";
import theme from "../../theme";
import Slide from "@material-ui/core/Slide";
import { Paper } from "@material-ui/core";
import { DesktopInformations } from "../../components/DesktopInformations";
import { RequestedSummary } from "../../components/RequestedSummary";
import { useNavigate } from "react-router-dom";
import { CpfMask, PhoneMask, dateMask, formatCEP } from "../../utils/masks";
export function Activation() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [openSummary, setOpenSummary] = useState<boolean>(false);
  const searchParams = new URLSearchParams(window.location.search);
  const slug = searchParams.get("gestor");
  const [body, setBody] = useState({
    name: "",
    birthday: "",
    cpf: "",
    phone: "",
    email: "",
    address: {
      zip_code: "",
      state: "",
      city: "",
      street: "",
      number: "",
      neighborhood: "",
      complement: "",
    },
  });

  async function handleSubmit() {
    navigate("/registro", { state: { data: body, manager_code: slug } });
  }

  const searchPostalCode = async () => {
  
    if (body?.address?.zip_code && body?.address?.zip_code.length === 8) {
      fetch(`https://viacep.com.br/ws/${body?.address?.zip_code}/json/`)
        .then((response) => response.json())
        .then((data) => {
          if (data.erro) return;
          setBody((prev: any) => ({
            ...prev,
            address: {
              ...prev.address,
              zip_code: data.cep,
              street: data.logradouro || '',
              neighborhood: data.bairro || '',
              city: data.localidade || '',
              state: data.uf || '',
            },
          }));
        })
        .catch((error) => {
          console.error('Erro ao buscar CEP:', error);
        });
    }
  };
  

  function hasEmptyFields(data: any) {
    for (const key in data) {
      if (typeof data[key] === "string" && data[key].trim() === "") {
        return true;
      }
    }
    return false;
  }

  const isDataCompleted = !hasEmptyFields(body);
  const isAddressCompleted = !hasEmptyFields(body?.address);

  useEffect(() => {
    if (isDataCompleted === true && isAddressCompleted === true) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [isDataCompleted, isAddressCompleted]);

  useEffect(() => {
    searchPostalCode();
  }, [body.address]);

  return (
    <>
      {openSummary ? (
        <Slide direction="right" in mountOnEnter unmountOnExit>
          <Paper elevation={4}>
            <RequestedSummary
              setOpenSummary={setOpenSummary}
              holderName={body.name}
              holderCpf={body.cpf}
              holderPhone={body.phone}
              holderEmail={body.email}
              holderAddress={body.address.street}
              holderCep={body.address.zip_code}
            />
          </Paper>
        </Slide>
      ) : (
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Box className={classes.wrapperInfo}>
              <Box className={classes.containerSteps}>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circle}>
                    <Typography className={classes.circleText}>1</Typography>
                  </Box>
                  <Typography className={classes.textStep}>Cadastro</Typography>
                </Box>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circleOutlined}>
                    <Typography className={classes.circleTextOutlined}>
                      2
                    </Typography>
                  </Box>
                  <Typography
                    className={classes.textStep}
                    style={{ color: "#908F8F", fontWeight: 500 }}
                  >
                    Forma de pagamento
                  </Typography>
                </Box>
                <Box className={classes.containerCircle}>
                  <Box className={classes.circleOutlined}>
                    <Typography className={classes.circleTextOutlined}>
                      3
                    </Typography>
                  </Box>
                  <Typography
                    className={classes.textStep}
                    style={{ color: "#908F8F", fontWeight: 500 }}
                  >
                    Comprovante de ativação
                  </Typography>
                </Box>
              </Box>
              <img src={logo} alt="Logo Dr Saude" className={classes.logo} />
              <Box
                className={classes.retangleOrder}
                onClick={() => setOpenSummary(true)}
              >
                <Typography className={classes.textOrder}>
                  Resumo do pedido
                </Typography>
                <KeyboardArrowDownIcon
                  style={{ color: theme.palette.text.primary }}
                />
              </Box>
            </Box>
            <Box className={classes.containerEnvinroment}>
              <Typography className={classes.textSecurity}>
                <HttpsIcon
                  style={{ width: "16px", color: theme.palette.text.secondary }}
                />
                ambiente seguro
              </Typography>
            </Box>
            <Box className={classes.wrapperStep}>
              <img src={mobileFirstStep} alt="Etapa da tela" />
            </Box>
          </Box>
          <Typography className={classes.title}>
            Ativação do cartão de benefícios
          </Typography>
          <Box className={classes.containerColumns}>
            <Box className={classes.box}>
              <Typography className={classes.boxTitle}>
                Informação do titular principal
              </Typography>
              <Box className={classes.separator} />
              <>
                <label className={classes.label}>Nome completo</label>
                <TextField
                  name="name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ marginTop: "5px", marginBottom: "20px" }}
                  onChange={(e) => setBody({ ...body, name: e.target.value })}
                  value={body.name}
                />
                <Box className={classes.divRow}>
                  <Box className={classes.labelAndInput}>
                    <label className={classes.label}>Data nascimento</label>
                    <TextField
                      name="birthday"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginTop: "5px", marginBottom: "20px" }}
                      onChange={(e) =>
                        setBody({ ...body, birthday: e.target.value })
                      }
                      value={dateMask(body.birthday) ?? body.birthday}
                      inputProps={{ maxLength: 10 }}
                    />
                  </Box>
                  <Box className={classes.labelAndInput}>
                    <label className={classes.label}>CPF</label>
                    <TextField
                      name="cpf"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginTop: "5px", marginBottom: "20px" }}
                      onChange={(e) =>
                        setBody({ ...body, cpf: e.target.value })
                      }
                      value={CpfMask(body.cpf) ?? body.cpf}
                      inputProps={{ maxLength: 14 }}
                    />
                  </Box>
                  <Box className={classes.labelAndInput}>
                    <label className={classes.label}>Telefone de contato</label>
                    <TextField
                      name="phone"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginTop: "5px", marginBottom: "20px" }}
                      onChange={(e) =>
                        setBody({ ...body, phone: e.target.value })
                      }
                      value={PhoneMask(body.phone) ?? body.phone}
                    />
                  </Box>
                </Box>
                <label className={classes.label}>E-mail</label>
                <TextField
                  name="email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ marginTop: "5px", marginBottom: "20px" }}
                  onChange={(e) => setBody({ ...body, email: e.target.value })}
                  value={body.email}
                />
                <label className={classes.label}>CEP</label>
                <TextField
                  name="cep"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ marginTop: "5px", marginBottom: "20px" }}
                  onChange={(e) =>
                    setBody({
                      ...body,
                      address: { ...body.address, zip_code: e.target.value },
                    })
                  }
                  value={formatCEP(body.address.zip_code)}
                  inputProps={{ maxLength: 9 }}
                />
                <label className={classes.label}>Endereço</label>
                <TextField
                  name="address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ marginTop: "5px", marginBottom: "20px" }}
                  onChange={(e) =>
                    setBody({
                      ...body,
                      address: { ...body.address, street: e.target.value },
                    })
                  }
                  value={body.address.street}
                />
                <Box className={classes.divRow}>
                  <Box className={classes.labelAndInput}>
                    <label className={classes.label}>Bairro</label>
                    <TextField
                      name="neighborhood"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginTop: "5px", marginBottom: "20px" }}
                      onChange={(e) =>
                        setBody({
                          ...body,
                          address: {
                            ...body.address,
                            neighborhood: e.target.value,
                          },
                        })
                      }
                      value={body.address.neighborhood}
                    />
                  </Box>
                  <Box className={classes.labelAndInput}>
                    <label className={classes.label}>Número</label>
                    <TextField
                      name="number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginTop: "5px", marginBottom: "20px" }}
                      onChange={(e) =>
                        setBody({
                          ...body,
                          address: { ...body.address, number: e.target.value },
                        })
                      }
                      value={body.address.number}
                    />
                  </Box>
                  <Box className={classes.labelAndInput}>
                    <label className={classes.label}>Complemento</label>
                    <TextField
                      name="complement"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginTop: "5px", marginBottom: "20px" }}
                      onChange={(e) =>
                        setBody({
                          ...body,
                          address: {
                            ...body.address,
                            complement: e.target.value,
                          },
                        })
                      }
                      value={body.address.complement}
                    />
                  </Box>
                </Box>
                <Box className={classes.divRow}>
                  <Box className={classes.labelAndInput}>
                    <label className={classes.label}>Cidade</label>
                    <TextField
                      name="city"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginTop: "5px", marginBottom: "20px" }}
                      onChange={(e) =>
                        setBody({
                          ...body,
                          address: { ...body.address, city: e.target.value },
                        })
                      }
                      value={body.address.city}
                    />
                  </Box>
                  <Box className={classes.labelAndInput}>
                    <label className={classes.label}>Estado</label>
                    <TextField
                      name="city"
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ marginTop: "5px", marginBottom: "20px" }}
                      onChange={(e) =>
                        setBody({
                          ...body,
                          address: { ...body.address, state: e.target.value },
                        })
                      }
                      value={body.address.state}
                    />
                  </Box>
                </Box>

                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={disabled}
                  sx={{
                    backgroundColor: theme.palette.primary.dark,
                    color: "#FFF",
                    fontSize: "18px",
                    marginTop: "30px",
                  }}
                  className={classes.button}
                >
                  Continuar
                </Button>
              </>
            </Box>
            <DesktopInformations
              setOpenSummary={setOpenSummary}
              holderName={body.name}
              holderCpf={body.cpf}
              holderPhone={body.phone}
              holderEmail={body.email}
              holderAddress={body.address.street}
              holderCep={body.address.zip_code}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
